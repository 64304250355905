
















import Vue from 'vue'
import SubmitButton from '@/components/buttons/SubmitButton.vue'

export default Vue.extend({
  name: 'CaptchaSubmitButton',
  components: { SubmitButton },
  data: () => ({
    captcha: '',
    matchCaptcha: '',
    dialog: false,
  }),
  props: { label: { type: String, default: '' } },
  methods: {
    generateRandomCaptcha(length: number) {
      let result = ''
      const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789'
      const charactersLength = characters.length
      for (let i = 0; i < length; i++) result += characters.charAt(Math.floor(Math.random() * charactersLength))
      return result
    },
  },
  watch: {
    matchCaptcha: function () {
      if (this.captcha === this.matchCaptcha) {
        this.dialog = false
        this.$emit('resolved')
      }
    },
    dialog: function () {
      if (this.dialog) this.captcha = this.generateRandomCaptcha(5)
    },
  },
})
