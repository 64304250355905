
























import Vue from 'vue'
import CancelButton from '@/components/buttons/CancelButton.vue'
import SubmitButton from '@/components/buttons/SubmitButton.vue'
import CardTitle from '@/components/CardTitle.vue'
import { errorMessages } from '@/utils/helpers'
import TenderService from '@/services/tender'
import CaptchaSubmitButton from '@/components/buttons/CaptchaSubmitButton.vue'

export default Vue.extend({
  name: 'ConfirmOffer',
  components: { CaptchaSubmitButton, CardTitle, SubmitButton, CancelButton },
  props: {
    id: { type: Number, default: 0 },
    totalConfirmedPower: { type: Number, default: 0 },
    confirmedPower: { type: Number, default: 0 },
    requestedPower: { type: Number, default: 0 },
    status: { type: String, default: '' },
  },
  data: function (): {
    dialog: boolean
    powerExceededReason: string
  } {
    return {
      dialog: false,
      powerExceededReason: '',
    }
  },
  methods: {
    confirm(): void {
      if (this.confirmedPower + this.totalConfirmedPower > this.requestedPower) {
        this.dialog = true
      } else {
        this.submit()
      }
    },
    async submit(): Promise<void> {
      const loader = this.$loading.show()
      try {
        const response = await TenderService.confirmBid(this.id, this.confirmedPower, this.powerExceededReason)
        if (response) {
          this.$toast.success(response.data.message)
        }
        this.powerExceededReason = ''
        this.$emit('updated', response.data.data)
      } catch (err) {
        if (err.response.data) {
          this.$emit('errors', err.response.data.errors || {})
          this.$toast.error(errorMessages(err.response.data.message))
          return
        }
        this.$toast.error(this.$t('somethingWentWrong') as string)
      } finally {
        loader.hide()
      }
    },
  },
})
