




import { defineComponent } from '@vue/composition-api'

export default defineComponent({
  name: 'OfferStatus',
  props: {
    status: {
      type: String,
      default: '',
    },
  },
  computed: {
    statusLabel(): string {
      if (this.status === 'confirmed') return 'confirmed'
      if (this.status === 'unavailable') return 'unavailable'

      return 'not_confirmed'
    },
    color(): string {
      if (this.status === 'confirmed') return 'green'
      if (this.status === 'unavailable') return 'secondary'

      return 'red'
    },
  },
})
