


























































import { defineComponent } from '@vue/composition-api'
import { TenderDTO } from '@/services/tender/types'
import { evaluationIntervals, EvaluationIntervals, formatDate } from '@/utils/helpers'
import Timer from '@/components/Timer.vue'
import TenderStatus from '@/views/Tender/partials/TenderStatus.vue'
import { VueEditor } from 'vue2-editor'

export default defineComponent({
  setup() {
    return { formatDate }
  },
  name: 'EvaluationDetailsHeader',
  props: { procurement: { type: Object as () => TenderDTO, required: true } },
  model: { prop: 'procurement' },
  components: { TenderStatus, Timer, VueEditor },
  computed: {
    evaluationIntervals(): EvaluationIntervals {
      return evaluationIntervals(
        this.procurement.endTime,
        this.procurement.endDate,
        this.procurement.offerValidityHours || 0,
        this.procurement.offerValidityMinutes || 0,
      )
    },
    syncProcurement: {
      get(): TenderDTO {
        return this.procurement
      },
      set(value: TenderDTO): void {
        this.$emit('input', value)
      },
    },
  },
})
