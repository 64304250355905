
















































import Vue from 'vue'
import CancelButton from '@/components/buttons/CancelButton.vue'
import CaptchaSubmitButton from '@/components/buttons/CaptchaSubmitButton.vue'
import TenderService from '@/services/tender'
import { TenderWithOffersDTO } from '@/services/tender/types'
import { ingoingDirection } from '@/constants/capacityDirections'
import EvaluationDetailsHeader from '@/views/Tender/Evaluation/EvaluationDetailsHeader.vue'
import EvaluationOffers from '@/views/Tender/Evaluation/EvaluationOffers.vue'
import { errorMessages } from '@/utils/helpers'
import DownloadButton from '@/components/buttons/DownloadButton.vue'
import { ProcurementStatus } from '@/services/myTender/types'

export default Vue.extend({
  name: 'EvaluateTender',
  components: { DownloadButton, EvaluationOffers, EvaluationDetailsHeader, CaptchaSubmitButton, CancelButton },
  props: { type: { type: String, default: '' } },
  data(): {
    id: string
    procurement: TenderWithOffersDTO
    ingoingDirection: string[]
    TenderService: typeof TenderService
  } {
    const id = 'id' in this.$route.params && this.$route.params.id

    if (!id) {
      throw new Error('EvaluateTender is missing required route param id')
    }

    return {
      id,
      ingoingDirection,
      procurement: {
        id: 0,
        name: '',
        description: '',
        publishDate: '',
        endDate: '',
        publishTime: '',
        endTime: '',
        type: '',
        status: '',
        lots: [],
        isLimited: false,
      },
      TenderService,
    }
  },
  computed: {
    downloadableLotBids(): boolean {
      return [
        ProcurementStatus.EVALUATING_OFFERS,
        ProcurementStatus.EVALUATING_EXPIRED,
        ProcurementStatus.CLOSED,
      ].includes(this.procurement.status as ProcurementStatus)
    },
  },
  methods: {
    async fetchItem(): Promise<void> {
      const loader = this.$loading.show()
      try {
        const { data } = await TenderService.offers(this.id)
        this.procurement = data.data
      } finally {
        loader.hide()
      }
    },
    async redirectBack(): Promise<void> {
      await this.$router.push({
        name: this.type === 'buy' ? 'BuyingTenders' : this.type === 'sell' ? 'SellingTenders' : '',
      })
    },
    updateData(data: TenderWithOffersDTO): void {
      this.procurement = data
    },
    async submit(): Promise<void> {
      const loader = this.$loading.show()
      try {
        const response = await TenderService.complete(this.id, { comments: this.procurement.comments || '' })
        if (response) {
          this.$toast.success(response.data.message)
        }
        await this.redirectBack()
      } catch (err) {
        if (err.response.data) {
          this.$toast.error(errorMessages(err.response.data.message))
          return
        }
        this.$toast.error(this.$t('somethingWentWrong') as string)
      } finally {
        loader.hide()
      }
    },
  },
  mounted() {
    this.fetchItem()
  },
})
