











import Vue from 'vue'

export default Vue.extend({
  name: 'TotalAcceptedPower',
  props: { value: { type: [Number, String], default: 0 } },
})
